<template>
    <div>
        <div style="display: flex">
            <el-company-list @on-click="onCompanyChange" v-if="showCompanyList"></el-company-list>
            <div style="width: calc(100vw - 370px)">
                <el-table
                    ref="table"
                    @on-mounted="showCompanyList=true"
                    :path="path"
                    manual
                    :columns="columns"
                    :params="params"
                    :search="search"></el-table>
            </div>
        </div>

        <a-modal
            v-model:visible="modal.show"
            :title="modal.title"
            cancelText="关闭"
            okText="回复"
            :confirmLoading="modal.confirmLoading"
            :destroyOnClose="true"
            :centered="true"
            @ok="modal.onOk()">
            <div>
                <div class="modal-content">
                    <template v-for="(item, index) in modal.datas" :key="item.id">
                        <div class="list">
                            <div class="item" :class="[item.type === 1? 'send': 'rec']">
                                <div class="content">{{ item.content }}</div>
                                <div class="time"><span
                                    v-if="item.type === 1 && item.status === 0">未读</span>{{ item.createTime }}
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="reply-box">
                    <input type="text" v-model="modal.formData.content">
                </div>
            </div>
        </a-modal>

    </div>
</template>

<script lang="ts">
import {defineComponent, h, reactive, ref, UnwrapRef} from 'vue';
import {message} from "ant-design-vue";
import Consult from "@/request/Consult";
import Company from "@/request/Company";
import SessionStorageKeys from "@/common/SessionStorageKeys";

const companyId = ref();
const isAdmin = ref<boolean>(false)
export default defineComponent({
        beforeCreate() {
            isAdmin.value = sessionStorage.getItem(SessionStorageKeys.type) === 'admin'
            companyId.value = isAdmin.value ? '' : sessionStorage.getItem(SessionStorageKeys.companyId)
        },
        setup() {
            const path = ref(Consult.path);
            const table = ref();
            const companies = ref<any>()
            const selectedCompany = ref<string[]>()
            const params = reactive({
                companyId: '',
                name: '',

            })

            const modal = reactive({
                title: '',
                show: false,
                confirmLoading: false,
                datas: [],
                formData: {
                    consultSessionId: '',
                    wageId: '',
                    content: ''
                },
                onOk() {
                    if (modal.formData.content.trim().length === 0) {
                        message.warn('请填写内容');
                        return;
                    }
                    modal.confirmLoading = true;
                    Consult.reply(modal.formData).then((body: any) => {
                        modal.confirmLoading = false;
                        if (body.code === 0) {
                            let data: any = modal.datas.concat(body.data);
                            modal.datas = data;
                            modal.formData.content = '';
                        }
                    })
                }
            })

            const onCompanyChange = (data: any) => {
                params.companyId = data.item.id;
                table.value ? table.value.flush(1) : null
            }

            return {
                showCompanyList: ref<boolean>(false),
                path,
                params,
                table,
                modal,
                companies,
                onCompanyChange,
                selectedCompany,
                search: ref([
                    {
                        tag: 'input',
                        label: '姓名',
                        key: 'name'
                    },
                    {
                        tag: 'select',
                        label: '所属单位',
                        key: 'companyId',
                        options: () => {
                            return new Promise(resolve => {
                                Company.getList({companyId: companyId.value}).then((body: any) => {
                                    let data: any = body.data;
                                    resolve(data.map((j: any) => {
                                        return {
                                            key: j.id,
                                            value: j.name
                                        }
                                    }));
                                })
                            })
                        }
                    }
                ]),
                columns: ref<any>([
                    {
                        title: '咨询标题',
                        dataIndex: 'type'
                    },
                    {
                        title: '姓名',
                        dataIndex: 'employeeName'
                    },
                    {
                        title: '手机号',
                        dataIndex: 'phone'
                    },
                    {
                        title: '所属单位',
                        dataIndex: 'companyName'
                    },
                    {
                        title: '最新咨询内容',
                        dataIndex: 'content'
                    },
                    {
                        title: '未读条数',
                        dataIndex: 'noReadCount'
                    },
                    {
                        title: '首次咨询时间',
                        dataIndex: 'createTime'
                    },
                    {
                        title: '最新咨询时间',
                        dataIndex: 'lastTime'
                    },
                    {
                        title: '操作',
                        dataIndex: 'action',
                        align: 'center',
                        type: 'editable-card',
                        customRender: ({record}: any) => {
                            return h(
                                'div',
                                {},
                                [
                                    h('a', {
                                        class: ['action'],
                                        onClick() {
                                            table.value.getById(record.id).then((body: any) => {
                                                let data = body.data;
                                                modal.title = record.type;
                                                modal.show = true;
                                                modal.formData.consultSessionId = data.id;
                                                modal.formData.wageId = data.wageId;
                                                modal.datas = data.list;
                                                Company.companyConsultList().then((body: any) => companies.value = body.data);
                                            })
                                        }
                                    }, '查看')
                                ]
                            );
                        }
                    }
                ])
            }
        }
    }
)
</script>

<style scoped lang="less">

.modal-content {
    height: 500px;
    overflow-y: scroll;

    .list {
        width: 100%;
        height: 50px;
        margin: 10px 0;

        .item {
            width: fit-content;
            max-width: 400px;
            background-color: red;
            padding: 3px 20px;
            border-radius: 50px;
            background-color: #eeeeee;

            .content {

            }

            .time {
                font-size: 12px;
                color: grey;
                float: right;

                > span {
                    margin: 0 5px;
                }
            }
        }

        .send {
            float: right;
            background-color: rgb(149, 236, 105);
        }

        .rec {
            float: left;
        }
    }
}

.reply-box {
    width: 100%;
    background-color: #eeeeee;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    > input {
        width: 90%;
        border-radius: 30px;
        margin: 0 auto;
        outline: none;
        padding: 3px 10px;
        border-color: rgba(255, 255, 255, .1);
    }
}

</style>