import {Request} from "@/Request";

class Consult extends Request {
    
    public path: string = '/consult-session';
    
    reply = (data: any) => {
        return this.post(`${this.path}/reply`, data);
    }
}

export default new Consult();