
import {defineComponent, h, reactive, ref, UnwrapRef} from 'vue';
import {message} from "ant-design-vue";
import Consult from "@/request/Consult";
import Company from "@/request/Company";
import SessionStorageKeys from "@/common/SessionStorageKeys";

const companyId = ref();
const isAdmin = ref<boolean>(false)
export default defineComponent({
        beforeCreate() {
            isAdmin.value = sessionStorage.getItem(SessionStorageKeys.type) === 'admin'
            companyId.value = isAdmin.value ? '' : sessionStorage.getItem(SessionStorageKeys.companyId)
        },
        setup() {
            const path = ref(Consult.path);
            const table = ref();
            const companies = ref<any>()
            const selectedCompany = ref<string[]>()
            const params = reactive({
                companyId: '',
                name: '',

            })

            const modal = reactive({
                title: '',
                show: false,
                confirmLoading: false,
                datas: [],
                formData: {
                    consultSessionId: '',
                    wageId: '',
                    content: ''
                },
                onOk() {
                    if (modal.formData.content.trim().length === 0) {
                        message.warn('请填写内容');
                        return;
                    }
                    modal.confirmLoading = true;
                    Consult.reply(modal.formData).then((body: any) => {
                        modal.confirmLoading = false;
                        if (body.code === 0) {
                            let data: any = modal.datas.concat(body.data);
                            modal.datas = data;
                            modal.formData.content = '';
                        }
                    })
                }
            })

            const onCompanyChange = (data: any) => {
                params.companyId = data.item.id;
                table.value ? table.value.flush(1) : null
            }

            return {
                showCompanyList: ref<boolean>(false),
                path,
                params,
                table,
                modal,
                companies,
                onCompanyChange,
                selectedCompany,
                search: ref([
                    {
                        tag: 'input',
                        label: '姓名',
                        key: 'name'
                    },
                    {
                        tag: 'select',
                        label: '所属单位',
                        key: 'companyId',
                        options: () => {
                            return new Promise(resolve => {
                                Company.getList({companyId: companyId.value}).then((body: any) => {
                                    let data: any = body.data;
                                    resolve(data.map((j: any) => {
                                        return {
                                            key: j.id,
                                            value: j.name
                                        }
                                    }));
                                })
                            })
                        }
                    }
                ]),
                columns: ref<any>([
                    {
                        title: '咨询标题',
                        dataIndex: 'type'
                    },
                    {
                        title: '姓名',
                        dataIndex: 'employeeName'
                    },
                    {
                        title: '手机号',
                        dataIndex: 'phone'
                    },
                    {
                        title: '所属单位',
                        dataIndex: 'companyName'
                    },
                    {
                        title: '最新咨询内容',
                        dataIndex: 'content'
                    },
                    {
                        title: '未读条数',
                        dataIndex: 'noReadCount'
                    },
                    {
                        title: '首次咨询时间',
                        dataIndex: 'createTime'
                    },
                    {
                        title: '最新咨询时间',
                        dataIndex: 'lastTime'
                    },
                    {
                        title: '操作',
                        dataIndex: 'action',
                        align: 'center',
                        type: 'editable-card',
                        customRender: ({record}: any) => {
                            return h(
                                'div',
                                {},
                                [
                                    h('a', {
                                        class: ['action'],
                                        onClick() {
                                            table.value.getById(record.id).then((body: any) => {
                                                let data = body.data;
                                                modal.title = record.type;
                                                modal.show = true;
                                                modal.formData.consultSessionId = data.id;
                                                modal.formData.wageId = data.wageId;
                                                modal.datas = data.list;
                                                Company.companyConsultList().then((body: any) => companies.value = body.data);
                                            })
                                        }
                                    }, '查看')
                                ]
                            );
                        }
                    }
                ])
            }
        }
    }
)
